/* eslint-disable import/named */
import React, { Component } from 'react'

import { graphql, navigate } from 'gatsby'

import loadable from '@loadable/component'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import ReactTooltip from 'react-tooltip'

import { Footer, Header, Layout, SEO } from '@components'

const Modal = loadable(() => import('@components/modal'))
const BLMap = loadable(() => import('@components/map'))
const ItemCarousel = loadable(() => import('@components/itemRow'))

const Head = (props: any) => <SEO {...props} />

class IndexPage extends Component {
  week: {
    date: string
    items: Array<Object>
    link: {
      text: string
      url: string
    }
    location: {
      map: string
      planet: string
      mapTile: string
      backgroundVideo: string
      background: GatsbyImageProps
    }
  }
  pubDate: Date
  thursday: Date
  // @ts-ignore
  props: {
    // URL location, not this week's location
    location: {
      hash: string
    }
    data: Object
    breakpoints: any
  }
  state: {
    isReset: boolean
    isOfferingModalOpen: boolean
    isMapModalOpen: boolean
  }

  /**
   * @param props.data.allContentfulWeek:object    Data for all weeks given.
   */
  constructor(props: { data: any }) {
    super(props)
    this.week = props.data.allContentfulWeek.edges[0].node

    // This thursday at midnight Chicago-time in UTC
    this.thursday = this.pubDate = new Date(this.week.date)
    this.thursday.setDate(this.thursday.getDate() + 7)
    this.thursday.setUTCHours(16, 0, 0, 0)

    // Modal state and If the published date is a week or more older than today,
    // that means the published data needs to be updated.
    this.state = {
      isReset: false,
      isOfferingModalOpen: false,
      isMapModalOpen: false,
    }
  }

  componentDidMount() {
    if (new Date() >= this.thursday) this.setState({ isReset: true })

    // on load, trigger modal if hash exists
    if (this.state.isReset) navigate('/', { replace: true })
    else if (this.props.location.hash === '#map') this.handleMapModalOpen()
    else if (this.props.location.hash === '#offerings')
      this.handleOfferingModalOpen()
  }

  handleOfferingModalOpen = () => {
    ReactTooltip.rebuild()
    this.setState({ isOfferingModalOpen: true })
  }

  handleOfferingModalClose = () => {
    this.setState({ isOfferingModalOpen: false })
    if (this.props.location.hash === '#offerings')
      navigate('/', { replace: true })
  }

  handleMapModalOpen = () => {
    ReactTooltip.rebuild()
    this.setState({ isMapModalOpen: true })
  }

  handleMapModalClose = () => {
    this.setState({ isMapModalOpen: false })
    if (this.props.location.hash === '#map') navigate('/', { replace: true })
  }

  render() {
    /**
     * @param this.location.backgroundVideo   Vimeo player URL.
     * @param this.location.map               Borderlands 3 map.
     * @param this.location.planet            Borderlands 3 planet.
     * @param this.location.mapTile           Lootlemon Leaflet.js background.
     */
    const { breakpoints } = this.props
    return (
      <Layout isRoot>
        <Head />
        <div className="background-image__wrapper">
          <GatsbyImage
            className="background-image"
            backgroundColor="black"
            loading="eager"
            // @ts-ignore
            image={this.week.location.background.gatsbyImageData}
            alt={this.week.location.map + ' Loading Screen'}
          />
        </div>
        {this.week.location.backgroundVideo && !breakpoints.sm && (
          // https://codepen.io/abennington/pen/ZONqqv
          <div className="vimeo">
            <iframe
              title="Background Video"
              src={
                this.week.location.backgroundVideo +
                '?transparent=1&background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1&muted=1'
              }
              // @ts-ignore
              allowtransparency="true"
              allowFullScreen
            />
          </div>
        )}
        <div className="tint" />
        {this.week.date && (
          <Header
            root
            expires={this.thursday}
            isReset={this.state.isReset}
            isRoot={true}
          />
        )}
        <section className="hero">
          {/* Hero body contents */}
          <div className="hero__body">
            {this.state.isReset && this.week.date ? (
              <div className="location">
                <h1 className="location__map">We're on the hunt!</h1>
                <h2 className="location__planet">Check back later today.</h2>
              </div>
            ) : (
              <>
                <div className="location">
                  <p className="location__prefix">
                    Maurice's Black Market vending machine is in...
                  </p>
                  <h1 className="location__map">
                    {this.week.location.map || "Maurice's Room"}
                  </h1>
                  <h2 className="location__planet">
                    {this.week.location.planet || 'Just Kidding'}
                  </h2>
                </div>
                <div className="cta">
                  <button onClick={this.handleMapModalOpen} className="button">
                    Open Map
                  </button>
                  <div className="button--outline">
                    <button
                      onClick={this.handleOfferingModalOpen}
                      className="button"
                    >
                      What is Maurice Selling?
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <Footer root link={this.week.link} isReset={this.state.isReset} />
        {/* Modal for the offering component */}
        <Modal
          header="What you might get."
          isOpen={this.state.isOfferingModalOpen}
          onClose={this.handleOfferingModalClose}
          tooltip={true}
        >
          <ItemCarousel />
        </Modal>
        {/* Modal for the map component */}
        <Modal
          header={`${this.week.location.map}, ${this.week.location.planet}`}
          isOpen={this.state.isMapModalOpen}
          onClose={this.handleMapModalClose}
          hasBlur={true}
        >
          <BLMap />
        </Modal>
      </Layout>
    )
  }
}

// @ts-ignore
export default withBreakpoints(IndexPage)

export const query = graphql`
  query {
    allContentfulWeek(sort: { fields: date, order: DESC }, limit: 1) {
      edges {
        node {
          date
          link {
            text
            url
          }
          location {
            map
            planet
            backgroundVideo
            background {
              gatsbyImageData(
                width: 1920
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
